/**
 * Pages
 *
 * Summary:
 *
 *  0. Page Builder
 *  - 0.1 Section "Content"
 *  - 0.2 Section "Featured Boxes"
 *  - 0.3 Section "Featured Information"
 *  - 0.4 Section "Latest News"
 *  - 0.5 Section "Contact Form"
 *  - 0.6 Section "Management"
 *  - 0.7 Section "Subnavigation"
 *  - 0.8 Section "two_three"
 *  1. Default Page
 *  2. Posts Page
*/

/* =======================================================================
   0. Page Builder
   ======================================================================= */

/* 0.1 Section "Content"
 ========================================================================= */
.section.content {

}

.section-title {
  display: flex;
  align-items: center;
  font-size: $font-size-h1;
  font-weight: 300;
  margin-bottom: 2.5rem;

  @include media-breakpoint-down(sm) {
    font-size: 1.9rem;
  }

  .title-icon  {
    height: 33px;
    width: auto;
    max-width: 33px;
    margin-right: 1.5rem;
  }
}

/* 0.2 Section "Featured Boxes"
 ========================================================================= */
.section.featured_boxes {

  //& > .container > .row {
  //  @include media-breakpoint-down(sm) {
  //    margin: 0;
  //  }
  //}

  .featured-boxes {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;
    justify-content: space-between;

    .box {
      width: 22%;
      padding: 0;
      position: relative;
      z-index: 2;
      @include media-breakpoint-down(md) {
        width: 45%;
        margin-bottom: 2rem;
      }
      @include media-breakpoint-down(xs) {
        width: 100%;
        margin-bottom: $spacer;
      }

      a {
        display: inline-block;
        width: 100%;
        color: $gray;
        transition: all .2s;

        .img {
          display: inline-block;
          width: 100%;
          height: auto;
          position: relative;
          transition: all .2s;

          @include media-breakpoint-down(sm) {
            top: -50%;
          }

          &-wrap {
            display: inline-block;
            margin-bottom: 1.5rem;

            @include media-breakpoint-down(sm) {
              overflow: hidden;
            }
          }
        }

        h4 {
          letter-spacing: -1px;
          font-size: 1.3rem;
          font-weight: 400;
        }

        &:hover {
          text-decoration: none;
          color: $exxel-dark-blue;

          .img {
            transform: scale(1.15);

            @include media-breakpoint-down(md) {
              transform: scale(1.1);
            }
          }
        }
      }
    }

    .half-width {
      @include media-breakpoint-up(md) {
        width: 45%;

        a:hover .img {
          transform: scale(1.05);
        }
      }
    }
  }
}

/* 0.3 Section "Featured Information"
========================================================================== */

.section.featured_information {
  position: relative;
  z-index: 5;
  padding-bottom: 1rem;

  &:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 80%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $exxel-baby-blue;
    z-index: -1;
  }

  & > .container > .row {
    @include media-breakpoint-down(xs) {
      margin: 0;
    }
  }

  .featured-wrap {
    display: flex;
    flex-wrap: wrap;
    background-color: $white;
    box-shadow: 10px 8px 20px 0 rgba(211,211,211,.5);
    padding: 4rem 4rem 0;

    @include media-breakpoint-down(lg) {
      padding: 3rem 3rem 0;
    }
    @include media-breakpoint-down(md) {
      padding: 3rem;
    }
    @include media-breakpoint-down(xs) {
      padding: 2rem;
    }

    .information {
      width: 50%;
      padding-bottom: 3rem;
      @include media-breakpoint-down(md) {
        width: 100%;
        padding-bottom: 0;
      }

      p {
        @include media-breakpoint-up(lg) {
          margin-bottom: 1.5rem;
        }
      }
    }

    .image {
      width: 50%;
      padding-left: 4rem;

      @include media-breakpoint-down(lg) {
        padding-left: 3rem;
      }
      @include media-breakpoint-down(md) {
        width: 100%;
        padding-left: 0;
        padding-top: 2rem;
      }

      img {
        width: 100%;
        height: auto;
        position: relative;
        top: -7rem;
        margin-bottom: -3rem;

        @include media-breakpoint-down(lg) {
          top: -6rem;
        }
        @include media-breakpoint-down(md) {
          top: 0;
          margin-bottom: 0;
        }
      }
    }
  }
}

/* 0.4 Section "Latest News"
========================================================================== */
.section.latest_news {
  .news-list {
    @extend .col-12;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 1rem;
    position: relative;

    @include media-breakpoint-up(md) {
      padding-bottom: 40px;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 85px;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: 100px;
    }
    @include media-breakpoint-down(md) {
      justify-content: space-around;
    }

    &:after {
      content: '';
      width: calc(100% - 30px);
      height: 1px;
      background-color: $light-gray;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 15px;
    }

    &:before {
      content: '';
      width: 17%;
      height: 1px;
      background-color: $exxel-light-blue;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 17%;
      z-index: 5;
    }

    .news-item {
      width: 30%;
      position: relative;
      display: inline-block;
      z-index: 2;
      @include media-breakpoint-down(md) {
        width: 45%;
        margin-bottom: 2rem;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
      a {
        width: 100%;
        display: inline-block;
        color: $gray;
        transition: all .2s;

        &:hover {
          text-decoration: none;
          color: $exxel-dark-blue;

          img {
            transform: scale(1.1);

            @include media-breakpoint-down(md) {
              transform: scale(1.05);
            }
            @include media-breakpoint-down(sm) {
              transform: scale(1.1);
            }
          }

          .read-more {
            color: $exxel-dark-blue;

            &:after {
              width: 70px;
              background-color: $exxel-dark-blue;
            }
          }
        }

        .img-wrap {
          display: inline-block;
          margin-bottom: 1.5rem;

          @include media-breakpoint-down(sm) {
            overflow: hidden;
          }

          img {
            position: relative;
            display: inline-block;
            width: 100%;
            height: auto;
            transition: all .2s;

            @include media-breakpoint-down(sm) {
              top: -50%;
            }
          }
        }
      }
      .latest_post_title {
        line-height: 1.3;
        margin-bottom: 2rem;
      }
    }
    .read-more {
      text-transform: uppercase;
      color: $exxel-light-blue;
      font-weight: 400;
      letter-spacing: 1px;
      font-size: $font-size-base;
      position: relative;
      transition: all .2s;

      &:after {
        content: '';
        display: inline-block;
        width: 40px;
        height: 1px;
        background-color: $exxel-light-blue;
        position: absolute;
        top: auto;
        bottom: - .5rem;
        left: 0;
        transition: .3s;
      }
    }
  }
}

/* 0.5 Section "Contact Form"
========================================================================== */
.section.contact_form {
  @extend .featured_information;

  .section-title {
    margin-bottom: 1rem;
  }

  .subtitle_1 {
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;
    
    &:after {
      content: '';
      display: inline-block;
      width: 60%;
      height: 1px;
      background-color: $exxel-light-blue;
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
    }
  }

  .subtitle_1 {
    margin-bottom: 1.5rem;
  }

  .subtitle_2 {
    margin-bottom: 1rem;
  }

  .form-block {
    form {
      position: relative;
    }

    .gform_heading,
    .gfield_label {
      display: none;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    input {
      width: 100%;
      padding: .5rem 0 .4rem;
      margin-bottom: 1rem;
      border: none;
      border-bottom: 1px solid $light-gray;
      outline: none;
      transition: all .2s;

      &::placeholder {
        color: $light-gray;
      }
      &:focus {
        border-bottom: 1px solid $exxel-light-blue;
      }
    }

    textarea {
      @extend input;
      height: 100px;
      min-height: 100px;
    }

    .gform_footer {
      position: absolute;
      top: auto;
      bottom: 0;
      right: 0;
      margin-bottom: 7px;
      display: flex;
      flex-wrap: wrap;

      input[type="submit"] {
        border-bottom: 1px solid $exxel-light-blue;
        color: $exxel-light-blue;
        text-transform: uppercase;
        order: 15;
        background-color: $white;

        &:hover {
          cursor: pointer;
        }
      }

      img {
        width: 15px;
        height: auto;
        order: 14;
        position: absolute;
        top: auto;
        bottom: 3rem;
        right: 0;
      }
    }

    .validation_error,
    .validation_message {
      color: #dc0000;
    }

    .investors-form {
      .gform_footer {
        margin-bottom: 0!important;
      }
    }
  }
}

.social-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  margin-left: -.5rem;

  .social-item a {
    padding: .5rem;
    color: $exxel-light-blue;
    font-size: 1.2rem;
    transition: all .2s;

    &:hover {
      color: $exxel-dark-blue;
    }
  }
}

/* 0.6 Section "Management"
========================================================================== */
.section.management {

  .team_member {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3rem;

    @include media-breakpoint-up(md) {
      margin-bottom: 5rem;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 100px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 120px;
    }
    &:last-child {
      margin-bottom: 0;
    }

    .photo {
      width: 40%;
      padding-right: 3rem;

      @include media-breakpoint-down(sm) {
        width: 100%;
        margin-bottom: 1.5rem;
        padding: 0;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
    .info {
      width: 60%;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }

      .position {
        margin-bottom: 1.5rem;
        text-transform: uppercase;
      }

      p {
        color: $text-gray;
        font-weight: $font-weight-base;
      }
    }

    .contact-text {
      text-transform: uppercase;
      margin-top: 1rem;
      display: inline-block;
      font-weight: $font-weight-medium;
    }

    .social-list {
      display: inline-flex;
      margin-left: .5rem;
    }
  }
}

/* 0.7 Section "Subnavigation"
  ========================================================================== */
.section.subnavigation {
  position: relative;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  section.subnavigation {
    overflow: hidden;
  }
  @include media-breakpoint-down(sm) {
    display: none;
  }
  section.subnavigation {
    background-color: rgba($exxel-baby-blue, .0);
    transition: background-color .2s, top .3s;
    padding-top: $spacer;
    padding-bottom: 0.25rem;
  }
  .fixed {
    position: fixed;
    top: 0;
    left: 50%;
    z-index: 100;
    width: 100%;
    transform: translate(-50%, 0);
    background-color: $exxel-baby-blue!important;
    .sub-row {
      padding: 0 15px;
      &:after {
        width: 100vw;
      }
    }
    .subnav-list {
      @include media-breakpoint-down(sm) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  .fixed-up {
    top: 8rem;
  }
  .sub-row {
    position: relative;
    justify-content: space-between;
    transition: padding-bottom .3s;
    &:after {
      content: '';
      position: absolute;
      top: 2.25rem;
      left: 50%;
      width: 100%;
      transform: translate(-50%, 0);
      transition: .2s;
      height: 1px;
      background-color: $exxel-dark-blue;
    }
    .home-link {
      color: $exxel-dark-blue;
      font-size: 1.1rem;
      line-height: 1.3rem;
      margin-bottom: map-get($spacers, 2);
      display: inline-block;
      text-transform: none;
    }
  }
  .subnav-list {
    margin: 0;
    padding-bottom: .5rem;
    padding-left: 0;
    list-style: none;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @include media-breakpoint-down(sm) {
      position: absolute;
      top: 35px;
      left: 0;
      margin: 0;
    }
  }
  li {
    display: inline;
    margin-left: 0;
    margin-right: $spacer;
  }
  a {
    font-size: $font-size-lg;
    white-space: nowrap;
    padding: 0!important;
    color: $exxel-dark-blue;
    transition: all .2s;

    &:hover {
      text-decoration: none;
      color: $exxel-dark-blue;
    }
  }
}

.logged-in .section.subnavigation .fixed {
  top: 1.75rem!important;
}

/* 0.8 Section "two_three"
  ========================================================================== */
.section.two_three {

  .section_list {
    margin: 0;
    padding: 0;

    li {
      display: flex;
      margin-bottom: 1rem;
    }

    .icon {
      width: 30%;
      text-align: center;

      @include media-breakpoint-down(md) {
        width: 100%;
        margin-bottom: 1.5rem;
      }

      img {
        width: auto;
        max-width: 100%;
        height: auto;
      }
    }

    .list-item-text {
      width: 70%;
      padding-left: 2rem;
      align-items: center;
      display: flex;

      @include media-breakpoint-down(md) {
        width: 100%;
        padding-left: 0;
      }
    }
  }
}

/* =======================================================================
   1. Default Page
   ======================================================================= */
.page {
  section.content {
    & > .container {
      padding: 4rem 15px;

      @include media-breakpoint-down(md) {
        padding: 3rem 15px;
      }
      @include media-breakpoint-down(xs) {
        padding: 2rem 15px;
      }
    }

    p {
      font-size: $font-size-lg;
    }
  }
}

/* =======================================================================
   2. Posts Page
   ======================================================================= */
.posts-container {
  @extend .section.latest_news;
  padding-top: 3rem;
  padding-bottom: 3rem;
  @include media-breakpoint-up(md) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  @include media-breakpoint-up(lg) {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 120px;
    padding-bottom: 120px;
  }

  .news-list {
    margin-bottom: 2rem;

    @include media-breakpoint-up(md) {
      padding-bottom: 3rem;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 80px;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: 100px;
    }
  }

  .news-item {
    margin-bottom: 2rem;
  }

  .posts-navigation {
    width: 100%;
    padding: 0 15px;
    .nav-links {
      display: flex;
      justify-content: space-between;
      width: 100%;

      a {
        color: $exxel-light-blue;
        font-size: $font-size-base;
        transition: .2s;

        &:hover {
          text-decoration: none;
          color: $exxel-dark-blue;
        }
      }
    }
  }

  .post-nav-links {
    margin-top: 3rem;
    padding-top: 3rem;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $light-gray ;

    a {
      color: $exxel-light-blue;
      font-size: $font-size-base;
      transition: .2s;

      &:hover {
        text-decoration: none;
        color: $exxel-dark-blue;
      }
    }
  }
}

.search-results .news-item {
  width: 100%!important;
  margin-bottom: 0!important;
}

.cont-with-img-right {
  display: flex;
  flex-wrap: wrap;

  div {
    width: 60%;
    margin-bottom: 2rem;
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }

  .right-img {
    width: 40%;

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    .two-elem {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      & > p,
      & > img {
        width: 50%!important;

        @include media-breakpoint-down(sm) {
          width: 100%!important;
        }
      }
    }

    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 1rem;
    }

    p,
    img {
      padding-left: 2rem;
      @include media-breakpoint-down(md) {
        padding-left: 0;
      }
    }
  }
}