// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$exxel-light-blue:      #4795C4;
$exxel-dark-blue:       #135C88;
$exxel-baby-blue:       #ECF8FF;

$white:                 #fff;
$light-gray:            #d1d1d1;
$text-gray:             #6f6f6f;
$gray:                  #333;
$black:                 #000;


// Fonts
$font-family-base:      'Quicksand', sans-serif !default;

$font-size-base:        .875rem !default;// 14px (14/16)
$font-size-lg:          1rem !default;   // 16px
$font-size-sm:          .75rem !default; // 12px (12/16)
$font-size-h1:          2.25rem !default; // 36px

$font-weight-light:     300 !default;
$font-weight-regular:   400 !default;
$font-weight-medium:    500 !default;
$font-weight-bold:      700 !default;