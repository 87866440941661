.sidebar {
  @include media-breakpoint-up(lg) {
    padding-left: 45px;
  }
  @include media-breakpoint-down(md) {

  }

  .widget {
    margin-bottom: 2rem;

    h3 {
      @extend h4;
    }

    ul {
      padding-left: 20px;
      margin: 0;
      list-style: none;

      a {
        color: $exxel-light-blue;
        font-size: $font-size-base;
        transition: .2s;
        display: inline-block;
        padding: .3rem 0;

        &:hover {
          text-decoration: none;
          color: $exxel-dark-blue;
        }
      }
    }
  }
}

.search-form {
  max-width: 350px;
  position: relative;

  label {
    width: 100%;
  }

  .search-field {
    width: 100%;
    padding-right: 5rem;
    border: none;
    border-bottom: 1px solid $light-gray;
    border-radius: 0;
    outline: none;
    transition: all .2s;
  }

  .search-submit {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    text-transform: uppercase;
    border-bottom: 1px solid $exxel-light-blue;
    color: $exxel-light-blue;
    background-color: transparent;
    border-radius: 0;
    transition: all .2s;
    outline: none;

    &:hover,
    &:focus {
      cursor: pointer;
      color: $exxel-dark-blue;
      background-color: transparent;
      border-bottom: 1px solid $exxel-dark-blue;
      box-shadow: none;
    }
  }
}