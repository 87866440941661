footer.section {
  padding: 3rem 0 1rem;
  background-color: $exxel-baby-blue;

  .brand  {
    display: inline-block;
    line-height: 40px;
    img {
      width: 130px;
      height: auto;
    }
  }

  .footer-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-bottom: 1px solid $exxel-light-blue;
    padding-bottom: 1.5rem;
    margin-bottom: 2.5rem;

    .widget_nav_menu {
      @include media-breakpoint-down(lg) {
        width: 100%;
        margin-top: 1rem;
      }

      .menu {
        display: flex;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;

        @include media-breakpoint-down(lg) {
          justify-content: center;
        }
        @include media-breakpoint-down(sm) {
          flex-wrap: wrap;

          li {
            width: 100%;
            margin: 0;

            a {
              padding-top: .5rem;
              padding-bottom: .5rem;
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }

    .menu-item {
      display: flex;
      align-items: center;
      margin: 0 1rem;
      text-transform: uppercase;

      a {
        letter-spacing: 1px;
        color: $gray;
        transition: .2s;
        font-weight: $font-weight-medium;

        &:hover {
          color: $exxel-dark-blue;
          text-decoration: none;
        }
      }

      &.active a {
        color: $exxel-dark-blue;
      }
    }

    .social-list {
      align-items: center;

      @include media-breakpoint-up(xl) {
        order: 3;
      }
    }
  }

  .footer-bottom {
    display: flex;
    flex-wrap: wrap;

    p {
      color: $exxel-light-blue;
      font-weight: $font-weight-base;
      font-size: $font-size-base;
    }

    .menu {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-wrap: wrap;

      &-item {
        margin-left: 2rem;

        a {
          padding: .5rem 0;
          font-weight: $font-weight-base;
          color: $exxel-light-blue;
          text-decoration: underline;
          transition: .2s;

          &:hover {
            color: $exxel-dark-blue;
          }
        }
      }
    }
  }
}