/**
 * Header
 *
 * Summary:
 *
 *  0. Banner
 *  1. Page Header
*/

/* ==========================================================================
   0. Banner
   ========================================================================== */
header.banner {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  transition: top .3s;
  background-color: $white;
  position: relative;

  .brand  {
    margin: 1rem 0;
    display: inline-block;
    line-height: 40px;
    img {
      width: 177px;
      height: auto;
    }
  }
  .header {
    display: flex;
    justify-content: space-between;
    .navigation {
      margin: ($spacer * 2) 0 1.8rem;
      display: flex;
      @include media-breakpoint-down(md) {
        display: block;
      }
      .navbar-collapse {
        @include media-breakpoint-down(xs) {
          padding-left: 0 !important;
        }
        @include media-breakpoint-down(md) {
          display: block;
          position: fixed;
          width: 100%;
          min-width: 100vw;
          height: auto;
          min-height: 100vh;
          top: -110%;
          left: 50%;
          transform: translate(-50%,0);
          background-color: $white;
          z-index: -100;
          opacity: 0;
          transition: .2s;
          padding-top: 5rem;
          padding-left: 1rem;
        }
      }
      a {
        text-transform: uppercase;
        letter-spacing: 1px;
        transition: .2s;
        font-size: $font-size-base;
        font-weight: $font-weight-medium;
        color: $gray;
        @include media-breakpoint-down(md) {
          font-size: 2.5rem;
        }

        &:hover {
          text-decoration: none;
          color: $exxel-light-blue;
        }
      }
      .current-menu-item a,
      .active a {
        color: $exxel-dark-blue;
      }
      .navbar-toggler {
        position: relative;
        outline: none;
        z-index: 101;
        width: 30px;
        height: 17px;
        background-color: transparent;
        border: none;
        transition: .3s;
        cursor: pointer;
        @include media-breakpoint-down(xs) {
          right: 20px;
        }
        .hamburger-top-line {
          display: block;
          width: 100%;
          height: 1px;
          background-color: $exxel-dark-blue;
          position: absolute;
          top: 0;
          left: 0;
          transition: .3s;
        }
        .hamburger-mid-line {
          @extend .hamburger-top-line;
          top: 8px;
          left: 0;
        }
        .hamburger-bot-line {
          @extend .hamburger-top-line;
          top: auto;
          bottom: 0;
        }
        &:hover {
          .hamburger-mid-line {
            left: 9px;
          }
          .hamburger-bot-line {
            left: -4px;
          }
        }

        &[aria-expanded="true"] {
          .hamburger-top-line {
            transform: rotate(45deg);
            top: 8px;
          }
          .hamburger-mid-line {
            background-color: transparent;
            transform: none;
          }
          .hamburger-bot-line {
            transform: rotate(-45deg);
            left: 0;
            top: 8px;
          }
        }
      }
    }
  }
  .menu-item {
    @include media-breakpoint-down(md) {
      width: 100%;
      margin-left: $spacer * 2.5;
    }

    a {
      padding: 1.5rem 0;
    }

    &.active {
      position: relative;

      a {
        color: $exxel-light-blue!important;
      }

      @include media-breakpoint-up(lg) {
        &:before {
          content: '';
          display: inline-block;
          width: 30px;
          height: 1px;
          background-color: $exxel-light-blue;
          position: absolute;
          top: auto;
          bottom: - .5rem;
          left: auto;
          right: 0;
        }
      }
    }
  }
  .menu-item ~ .menu-item {
    @include media-breakpoint-up(lg) {
      margin-left: $spacer * 2.5;
    }
  }

  .relative-block {
    position: relative;
  }
  .show {
    @include media-breakpoint-down(md) {
      position: absolute !important;
      z-index: 100!important;
    }

    opacity: 1!important;
    top: 0!important;
  }

  .menu-item-has-children {
    position: relative;
    z-index: 1;
    transition: height .2s;

    @include media-breakpoint-down(md) {
      height: 60px;
      overflow: hidden!important;
    }

    & > a {
      border-bottom-color: transparent!important;

      @include media-breakpoint-down(md) {
        display: inline-block;
        position: relative;
        padding: 0;

        &:after {
          content: "\f107";
          width: 36px;
          height: 60px;
          line-height: 60px;
          font-family: FontAwesome;
          position: absolute;
          top: 0;
          left: auto;
          right: -41px;
          display: inline-block;
          transition: .2s;
          color: $exxel-dark-blue!important;
          padding: 0 5px;
        }
      }
    }

    &:hover {
      &:after {
        transform: rotate(-180deg);
      }
    }

    @include media-breakpoint-up(lg) {
      overflow: hidden;

      &:after {
        content: "\f107";
        width: 10px;
        height: 20px;
        font-family: FontAwesome;
        position: relative;
        display: inline-block;
        transition: .2s;
      }

      & > a {
        padding: 1.5rem 0!important;
      }
    }

    .sub-menu {
      list-style: none;

      @include media-breakpoint-down(md) {
        padding: 0.5rem 0;

        a {
          text-transform: none!important;
          padding: .35rem 1rem;
          font-size: 1.3rem!important;
          display: inline-block;
        }
      }

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 2.5rem;
        left: 50%;
        opacity: 0;
        z-index: -100;
        transform: translate( -50%, 0);
        box-shadow: 6px 6px 25px 0 rgba(28,49,45,.12);
        min-width: 250px;
        background-color: $exxel-baby-blue;
        padding: 0;
        border-bottom: 5px solid $exxel-dark-blue;

        a {
          padding: .5rem 1rem;
          border-bottom: 1px solid $exxel-light-blue;
          display: inline-block;
          width: 100%;
          color: $text-gray!important;

          &:hover {
            color: $exxel-dark-blue!important;
          }
        }
      }

      li {
        margin: 0!important;
      }
    }

    .show-dropdown {
      transition: all .3s;
      opacity: 1;
      z-index: 50;
    }

    &.visible {
      overflow: visible;
    }
  }

  .mob-menu-open {
    & > a:after {
      transform: rotate(-180deg);
    }
  }
}

/* ==========================================================================
   1. Page Header
   ========================================================================== */
section.page-header {

  // Default
  .content {
    @extend .col-sm-12;
    h1 {
      margin-bottom: 0;
      font-size: $h1-font-size;
      font-weight: 100;
      padding-bottom: $spacer / 2;
      position: relative;
      span,
      b {
        position: relative;
        display: inline-block;
      }
    }
    br {
      @include media-breakpoint-down(md) {
        display: none
      }
    }
  }

  // Without Image
  &.without-image {
    padding-top: 1rem;
    h1 {
      @extend .gradient-blue;
      padding: 3rem;
      line-height: 1.3;
      color: $white;
      font-size: $font-size-h1;
    }
  }

  // With Image
  &.with-image {
    padding-top: 250px;
    background-size: cover!important;
    background-position: center center;
    background-repeat: no-repeat;

    @include media-breakpoint-down(lg) {
      padding-top: 210px;
    }
    @include media-breakpoint-down(md) {
      padding-top: 180px;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 150px;
    }

    h1 {
      @extend .gradient-blue;
      @extend .col-xl-8;
      @extend .col-lg-9;
      @extend .col-md-10;
      @extend .col-12;
      padding: 3rem;
      line-height: 1.3;
      color: $white;
      font-size: $font-size-h1;
      position: relative;
      top: 50%;

      @include media-breakpoint-down(sm) {
        padding: 2rem;
      }
    }
  }

  // Slider
  &.slider {
    padding: 0!important;
    margin: 1rem 0 0;

    .swiper-slide {
      padding: 2rem 0 3rem;

      @include media-breakpoint-down(md) {
        padding: 0;
      }
      @include media-breakpoint-up(lg) {
        background-size: 85%!important;
        background-position-x: -15vw!important;
      }
      @include media-breakpoint-up(md) {
        padding: 4rem 0 7rem;
      }
      @media (min-width: 1400px) {
        padding: 4rem 0 6rem;
        background-size: 60vw auto !important;
        background-position-x: 5vw!important;
      }

      @media (min-width: 1600px) {
        padding: 4rem 0 6rem;
        background-size: 55vw auto !important;
        background-position-x: 7vw!important;
      }

      height: initial;
      background-size: cover!important;
      box-sizing: border-box;
      text-align: center;

      h1 {
        font-size: 1.5rem;
        color: $white;
        text-align: left;
      }

      .cta {
        @include media-breakpoint-up(lg) {
          position: absolute;
          top: auto;
          bottom: -3rem;
          left: 50%;
          transform: translateX(-50%);
        }
        @include media-breakpoint-down(md) {
          color: $white;
        }
      }
    }

    .swiper-controls {
      position: absolute;
      top: -1px;
      right: 1.5rem;
      width: 20%;
      height: 40px;
      display: flex;
      justify-content: space-around;
      background-color: rgba($white, .4);
      z-index: 10;

      @include media-breakpoint-down(md) {
        width: auto;
        left: 50%;
        right: auto;
        transform: translate(-50%, 0);
        background-color: transparent;
      }
      @include media-breakpoint-down(sm) {
        display: none;
      }

      .swiper-button-prev,
      .swiper-button-next {
        top: 0;
        width: 15px;
        height: 35px;
        margin-top: 0;
        background-size: 9px 15px;
        position: relative;
        display: inline-block;
      }

      @media (min-width: 1600px) {
        transform: scale(1.25);
      }
    }

    .swiper-pagination {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      top: 0;
      bottom: auto;
      position: relative;

      @include media-breakpoint-down(md) {
        margin: 0 2rem;
      }
    }
    .swiper-pagination-bullet {
      width: auto;
      height: auto;
      border-radius: 0;
      background-color: transparent;
      color: #ACACAC;
      padding: .5rem;
      margin: 0 .5rem;
      opacity: .8;
      font-size: $font-size-base;

      @include media-breakpoint-down(xs) {
        margin: 0 1rem;
      }

      &-active {
        color: $exxel-light-blue;
        opacity: 1;
      }
    }
  }

  .slide-info-wrap {
    position: relative;
    z-index: 1;

    @include media-breakpoint-down(md) {
      background: linear-gradient(to top, $exxel-dark-blue, $exxel-light-blue);
    }

    .container {
      box-sizing: border-box;
      .row {
        justify-content: center;

        @include media-breakpoint-up(lg) {
          justify-content: flex-end;
        }
      }
    }
    .content {
      padding: 3rem;
      position: relative;
      @extend .gradient-blue;
      @extend .col-xl-5;
      @extend .col-lg-4;
      @extend .col-sm-10;

      @include media-breakpoint-down(lg) {
        padding: 3rem 2rem;
      }
    }
  }
}