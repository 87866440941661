/**
 * Global Variabless
 *
 * Summary:
 *
 *  0. Sections
 *  1. Typography
 *  2. Backgrounds
 *  3. Miscellanous
 *  - 0.1 Wrappers
 *  - 0.2 Extenders
 *  - 0.3 Box Offset
 *  - 0.4 Logos
 *  4. Shortcodes
 *  - 0.1 CTA
*/

/* ==========================================================================
   0. Sections
   ========================================================================== */
body {
  background-color: $white;
  font-family: $font-family-base;
}

.section {
  position: relative;
  max-width: 100%;

  // Padding: Default
  padding: ($spacer * 3) 0;
  @include media-breakpoint-up(md) {
    padding: ($spacer * 5) 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 100px 0;
  }
  @include media-breakpoint-up(xl) {
    padding: 120px 0;
  }
  // Padding Sizes
  &.padding-large {
    padding: ($spacer * 5) 0;
    @include media-breakpoint-up(md) {
      padding: ($spacer * 6) 0;
    }
    @include media-breakpoint-up(lg) {
      padding: ($spacer * 7) 0;
    }
  }
  &.padding-small {
    padding: ($spacer * 3) 0 !important;
  }
  &.padding-no-top {
    padding-top: 0
  }
  &.padding-no-bottom {
    padding-bottom: 0;
  }
  &.padding-none {
    padding-top: 0;
    padding-bottom: 0;
  }
  p {
    color: $gray;
  }
}

/* ==========================================================================
   1. Typography
   ========================================================================== */
h1, h2 {
  b {
    color: $gray;
    font-weight: 300;
  }
  color: $gray;
}
h3, h4, h5, h6 {
  //letter-spacing: 0.07125rem;
}
h4 {
  letter-spacing: -1px;
  font-size: 1.3rem;
  font-weight: $font-weight-regular;
}
p {
  font-size: $font-size-lg;
}

/* ==========================================================================
   2. Backgrounds
   ========================================================================== */
&.bg-cover {
  background-position: 50% 50%;
  background-size: cover;
  text-decoration: none;
}
&.bg-cover-transparency {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
&.bg-light {
  background-color: $gray-300 !important;
  color: $black;
  h1, h2, h3, h4, h5, h6 {
    color: $gray-700;
  }
  a {
    color: $blue;
    &:hover {
      text-decoration: none;
    }
  }
}
&.bg-lighter {
  @extend .bg-light;
  background-color: $gray-100 !important;
}
&.bg-lightest {
  @extend .bg-light;
  background-color: $gray-100 !important;
}
&.bg-dark {
  background-color: $gray-700 !important;
}
&.bg-darker {
  background-color: $gray-800 !important;
}
&.bg-darkest {
  background-color: $gray-900 !important;
}
&.bg-primary {
  background-color: $blue !important;
}
&.bg-image {
  @extend .bg-cover;
}

/* ==========================================================================
   3. Miscellaneous
   ========================================================================== */

/* 0.1 Wrappers
 ========================================================================== */
.wrapper-small {
  @extend .mx-auto;
  @include make-col-ready();
  @include media-breakpoint-up(md) {
    @include make-col(8);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(6);
  }
}
.wrapper-medium {
  @extend .mx-auto;
  @include make-col-ready();
  @include media-breakpoint-up(md) {
    @include make-col(10);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
  }
}
.wrapper-large {
  @extend .mx-auto;
  @include make-col-ready();
  @include media-breakpoint-up(lg) {
    @include make-col(10);
  }
}
.wrapper-full {
  @include make-col-ready();
  @include media-breakpoint-up(lg) {
    @include make-col(12);
  }
}

/* 0.2 Extenders
 ========================================================================== */
.centered {
  @include media-breakpoint-up(sm) {
    text-align: center;
  }
}
.shadow {
  box-shadow: 0px 0px 40px 0px $black;
  transition: all 0.1s ease-in-out;
  &:hover {
    box-shadow: 0px 0px 40px 0px $gray;
  }
}

/* 0.3 Box Offset
 ========================================================================== */

/* 0.4 Logos
   ========================================================================== */

/* 4.1 CTA
   ========================================================================== */
.cta {
  color: $exxel-light-blue;
  display: inline-block;
  margin-top: $spacer * 1;
  position: relative;
  line-height: 1.3rem;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  transition: all .2s;

  &:hover {
    cursor: pointer;
    text-decoration: none;
    color: $exxel-dark-blue;
    &:after {
      width: 80px;
      background-color: $exxel-dark-blue;
    }
  }
  &:after {
    content: '';
    position: absolute;
    top: auto;
    bottom: -.5rem;
    left: 0;
    display: inline-block;
    width: 60px;
    height: 1px;
    transition: all .2s;
    background-color: $exxel-light-blue;
  }
}

/* ==========================================================================
   5. Helper classes
   ========================================================================== */
.content a {
  color: $exxel-light-blue;
}

.no-scroll {
  overflow: hidden;
}

.hide {
  display: none;
}

.vertical-center {
  position: relative;
  div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
}

.mt-25 {
  margin-top: 2.5rem;
}

.relative {
  position: relative;
}

.gradient-blue {
  background: linear-gradient(to top, $exxel-dark-blue, $exxel-light-blue);
}